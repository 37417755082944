<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table custom-table-padding">
                <thead>
                    						
                    <tr>
                        <th>Photo</th>
                        <th class="patient-details">Patient Details</th>
                        <th class="admission-no">
                            <span class="d-block">Admission No |</span>
                            <span class="d-block">Date & Time</span>
                        </th>
                        <th>Ward/Bed/Cabin</th>
                        <th>
                            <span class="d-block">Consultant</span>
                            <span>(Reffered doctor)</span>
                        </th>
                        <th>Admitted By</th>
                        <th class="action-th">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in tableItems" :key="i">

                        <td>
                            <img :src=item.photo alt="avatar" class="profile-pic">
                        </td>

                        <td> 
                            <div class="col">
                                <strong>
                                    <h4>{{ item.patientName }} ({{ item.age }} years, {{ item.gender }}) </h4>
                                </strong>
                                {{ item.mobile }} | {{ item.id }}
                            </div>
                        </td>

                        <td>
                            <div class="col">
                                <h5 class="text-bold">{{ item.admission_no }}</h5>
                               <span>{{ item.admission_date }}</span> 
                            </div>
                        </td>

                        <td>
                            {{ item.word_no }}
                        </td>

                        <td>
                            {{ item.consultant_name }}
                        </td>

                        <td>
                            {{ item.admitted_by }}
                        </td>

                        <td>
                            <div class="action">
                                <button class="btn btn-outline-primary btn-sm print">
                                    <i class="fas fa-print"></i>
                                </button>
                                <button @click="goToDoctorOrder" class="btn btn-primary btn-sm">
                                    <i class="fas fa-plus"></i>
                                    Bill
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
</div>
</template>

<script setup>
    import { useRoute, useRouter } from "vue-router";

    const $route = useRoute();
    const $router = useRouter();

    const props = defineProps({
        tableItems: {
            type: Array,
        }
    })
    const goToDoctorOrder = () => {
        $router.push({name: 'invoice-pharmacy',
            params: $route.params,
            query: {}

        })
    }
</script>

<style scoped>

.admission-no{
    width: 15%;
}
.patient-details{
    width: 25%;
}
.print-btn{
    margin-right: 5px;
}
.action-th{
    width: 15%;
    text-align: center;
}
.action{
    text-align: center;
}
.action .print{
    margin-right: 4px;
}

.profile-pic{
    width: 65px;
}
</style>