<template>
    <div>
        <div class="card">

            <div class="bg-blue-light p-1">
                <TitleButton 
                    :showBtn="false"
                    :showAddNew="false"
                    title="IPD Medicine Order"
                    btnTitle="+ Create"
                />
            </div>

            <div class="px-2">
                <DateQuerySetter
                    btnTitle="Go"
                    @onClickGo="onClickGo"
                >
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                        <label for="colFormLabel" class="col-form-label">Search</label>
                        <div class="input-group input-group-merge w-100">

                            <span class="input-group-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search text-muted">
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                </svg>
                            </span>

                            <input 
                                type="text" class="form-control"
                                placeholder="Search/Scan Barcode - by Patient ID" aria-label="Search..."
                                aria-describedby="chat-search"
                            />

                        </div>
                    </div>

                    <div class="col-6 col-sm-2 col-md-2 col-lg-2">
                        <label for="colFormLabel" class="col-form-label">Admission no</label>
                        <div class="input-group input-group-merge w-100">

                            <input 
                                type="text" class="form-control"
                                placeholder="Admission no" aria-label="Search..."
                                aria-describedby="chat-search"
                            />

                        </div>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                        <label for="colFormLabel" class="col-form-label">Select Consultant </label>
                        <v-select 
                            placeholder="Select consutant" class="w-100" 
                            :options="[]" label="name"
                            :reduce="(name) => name.id" 
                        />
                    </div>

                </DateQuerySetter>
            </div>

            <div class="col-12 px-2 mt-2">
                <ListTable :tableItems="tableItems"/>
            </div>
        </div>

        <div class="card">

            <div class="bg-blue-light p-1">
                <TitleButton 
                    :showBtn="false"
                    :showAddNew="false"
                    title="OPD Medicine Order"
                    btnTitle="+ Create"
                />
            </div>

            <div class="px-2">
                <DateQuerySetter
                    btnTitle="Go"
                    @onClickGo="onClickGo"
                >
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                        <label for="colFormLabel" class="col-form-label">Search</label>
                        <div class="input-group input-group-merge w-100">

                            <span class="input-group-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search text-muted">
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                </svg>
                            </span>

                            <input 
                                type="text" class="form-control"
                                placeholder="Search/Scan Barcode - by Patient ID" aria-label="Search..."
                                aria-describedby="chat-search"
                            />

                        </div>
                    </div>

                    <div class="col-6 col-sm-2 col-md-2 col-lg-2">
                        <label for="colFormLabel" class="col-form-label">Admission no</label>
                        <div class="input-group input-group-merge w-100">

                            <input 
                                type="text" class="form-control"
                                placeholder="Admission no" aria-label="Search..."
                                aria-describedby="chat-search"
                            />

                        </div>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                        <label for="colFormLabel" class="col-form-label">Select Consultant </label>
                        <v-select 
                            placeholder="Select consutant" class="w-100" 
                            :options="[]" label="name"
                            :reduce="(name) => name.id" 
                        />
                    </div>

                </DateQuerySetter>
            </div>

            <div class="col-12 px-2 mt-2">
                <ListTable :tableItems="tableItems"/>
            </div>
        </div>
    </div>
</template>

<script setup>
    import TitleButton from '@/components/atom/TitleButton';
    import ListTable from '@/components/molecule/company/hospital/pharmacy-billing/PharmacyBillingTable.vue';
    import { reactive } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';

    const $router = useRouter();
    const $route = useRoute();
    const tableItems = [
        {
            id: "2uu321i4A", 
            photo: "/app-assets/images/avatar.png", 
            patientName: "Abdur", 
            age: 40, 
            gender: "male",
            mobile: "017556483",
            consultant_name: "Dr. Zahidul Islam",
            word_no: "BA-04",
            admission_no: "IPD-2023-1022",
            admission_date: "12-Jun-23, 10.30 AM",
            admitted_by: "Dr. Jamal Uddin",
        }
    ];

    const onClickGo = () => {
        
    }
</script>

<style>

</style>